export const paymentOptions = [
    { value: '', label: 'Select Payment Method' },
    { value: 'Transfer', label: 'Transfer' },
    { value: 'Cash', label: 'Cash' },
    { value: 'Cheque', label: 'Cheque' },
]


export const paymentType =[
    { value: '', label: 'Select Payment Type' },
    { value: 'Paid in Full', label: 'Paid in Full' },
    { value: 'Installment', label: 'Installment' },
    
]